import Typography from '../../../components/Typography';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  defaultChildSexOptions,
  defaultFeedingCourseOptions,
  defaultOtherPregnancyOutcomes,
  defaultPregnanancyLocationOfDelivery,
  defaultPregnancyInfo,
  defaultPregnancyOutcomes,
} from '../defaults';
import { useObstetricHistoryInfoForm } from '../hooks/useObstetricHistoryInfoForm';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import {
  ChildInfoDTO,
  PatientInfoDTO,
  PregnancyInfoDTO,
} from '@aster/shared/dtos/patient';
import { CircularProgress } from '@mui/material';
import { Button } from '@aster/client/ui/Button/Button';
import { InputMask } from '@react-input/mask';
import { Input } from '@aster/client/ui/Input/Input';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { LAST_SAVED_OBSTETRIC_INFORMATION } from '../constants';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { forwardRef, useImperativeHandle } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';

const ObstetricInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit: () => obstetricHistoryInfoForm.handleSubmit(),
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_OBSTETRIC_INFORMATION);
    const lastSavedString = lastSaved
      ? `Last saved 
  ${dayjs().diff(dayjs(lastSaved), 'minutes')}
   minutes ago`
      : '';

    const obstetricHistoryInfoForm = useObstetricHistoryInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save({
          pregnancies: value,
        })?.then(() => {
          storePreference(
            LAST_SAVED_OBSTETRIC_INFORMATION,
            dayjs().toISOString()
          );
        });
      },
    });

    const save = useDebouncedCallback(async (value) => {
      await updatePatientMutation.mutateAsync(value);
    }, 1000);

    const readonly = isPatientReadonly(patientInfo);

    const { patient: patientID } = useParams<{ patient: string }>();

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={obstetricHistoryInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Obstetric History"
                    variant="h5"
                    customClass="font-semibold mb-5"
                  ></Typography>
                  {obstetricHistoryInfoForm.state.values.pregnancies.length ===
                    0 && (
                    <Label className="m-0">
                      Have you been pregnant before (not including current
                      pregnancy)?
                    </Label>
                  )}
                  <obstetricHistoryInfoForm.Field
                    name={'pregnancies'}
                    mode="array"
                  >
                    {(field) => (
                      <>
                        {field.state.value.map((_, index) => (
                          <div
                            key={index}
                            className="flex flex-col p-6 gap-y-6 border border-gray-300 rounded-[8px]"
                          >
                            <div className="flex justify-between">
                              <Typography
                                variant={'h6'}
                                customClass="text-black"
                              >
                                Pregnancy {index + 1}
                              </Typography>
                              <Button
                                type="button"
                                variant={'default'}
                                size={'sm'}
                                className="text-bodyMedium font-medium bg-gray-100 text-black-500 hover:bg-gray-200"
                                onClick={() => {
                                  const updatedPregnancies = [
                                    ...field.state.value,
                                  ];
                                  updatedPregnancies.splice(index, 1);
                                  field.handleChange(updatedPregnancies);
                                  obstetricHistoryInfoForm.handleSubmit();
                                }}
                                disabled={readonly}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                                Remove
                              </Button>
                            </div>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.pregnancyOutcome`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Label className="mb-3">
                                    What was the outcome of this pregnancy?
                                  </Label>
                                  <RadioGroup
                                    className="flex flex-col items-start gap-3"
                                    value={
                                      subField.state
                                        .value as PregnancyInfoDTO['pregnancyOutcome']
                                    }
                                    onValueChange={(value) => {
                                      subField.handleChange(
                                        value as PregnancyInfoDTO['pregnancyOutcome']
                                      );
                                      obstetricHistoryInfoForm.handleSubmit();
                                    }}
                                  >
                                    {defaultPregnancyOutcomes.map((outcome) => (
                                      <div className="flex gap-2 items-center">
                                        <RadioItem
                                          className="shrink-0"
                                          id={`${subField.name}-${outcome.value}`}
                                          value={outcome.value}
                                          disabled={readonly}
                                        >
                                          <RadioIndicator />
                                        </RadioItem>
                                        <Label
                                          htmlFor={`${subField.name}-${outcome.value}`}
                                          className="m-0 cursor-pointer text-aster-secondary font-normal"
                                        >
                                          {outcome.text}
                                        </Label>
                                      </div>
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <div className="flex flex-col">
                              <Label className="mb-2">
                                Labor: First stage duration
                              </Label>
                              <div className="flex gap-4">
                                <obstetricHistoryInfoForm.Field
                                  name={`pregnancies[${index}].pregnancy.hoursFirstStageLabor`}
                                >
                                  {(subField) => (
                                    <FormControl>
                                      <Input
                                        placeholder="Hours"
                                        type="number"
                                        value={
                                          subField.state.value === 0
                                            ? ''
                                            : subField.state.value
                                        }
                                        onChange={(event) => {
                                          const numberValue = Number(
                                            event.target.value
                                          );
                                          if (
                                            numberValue > -1 &&
                                            numberValue <= 52
                                          ) {
                                            subField.handleChange(numberValue);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (subField.state.meta.isDirty) {
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                        disabled={readonly}
                                      />
                                    </FormControl>
                                  )}
                                </obstetricHistoryInfoForm.Field>
                                <obstetricHistoryInfoForm.Field
                                  name={`pregnancies[${index}].pregnancy.minutesFirstStageLabor`}
                                >
                                  {(subField) => (
                                    <FormControl>
                                      <Input
                                        placeholder="Minutes"
                                        type="number"
                                        value={
                                          subField.state.value === 0
                                            ? ''
                                            : subField.state.value
                                        }
                                        onChange={(event) => {
                                          const numberValue = Number(
                                            event.target.value
                                          );
                                          if (
                                            numberValue > -1 &&
                                            numberValue <= 52
                                          ) {
                                            subField.handleChange(numberValue);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (subField.state.meta.isDirty) {
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                        disabled={readonly}
                                      />
                                    </FormControl>
                                  )}
                                </obstetricHistoryInfoForm.Field>
                              </div>
                            </div>

                            <div className="flex flex-col">
                              <Label className="mb-2">
                                Labor: Second stage duration
                              </Label>
                              <div className="flex gap-4">
                                <obstetricHistoryInfoForm.Field
                                  name={`pregnancies[${index}].pregnancy.hoursSecondStageLabor`}
                                >
                                  {(subField) => (
                                    <FormControl>
                                      <Input
                                        placeholder="Hours"
                                        type="number"
                                        value={
                                          subField.state.value === 0
                                            ? ''
                                            : subField.state.value
                                        }
                                        onChange={(event) => {
                                          const numberValue = Number(
                                            event.target.value
                                          );
                                          if (
                                            numberValue > -1 &&
                                            numberValue <= 52
                                          ) {
                                            subField.handleChange(numberValue);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (subField.state.meta.isDirty) {
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                        disabled={readonly}
                                      />
                                    </FormControl>
                                  )}
                                </obstetricHistoryInfoForm.Field>
                                <obstetricHistoryInfoForm.Field
                                  name={`pregnancies[${index}].pregnancy.minutesSecondStageLabor`}
                                >
                                  {(subField) => (
                                    <FormControl>
                                      <Input
                                        placeholder="Minutes"
                                        type="number"
                                        value={
                                          subField.state.value === 0
                                            ? ''
                                            : subField.state.value
                                        }
                                        onChange={(event) => {
                                          const numberValue = Number(
                                            event.target.value
                                          );
                                          if (
                                            numberValue > -1 &&
                                            numberValue <= 52
                                          ) {
                                            subField.handleChange(numberValue);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (subField.state.meta.isDirty) {
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                        disabled={readonly}
                                      />
                                    </FormControl>
                                  )}
                                </obstetricHistoryInfoForm.Field>
                              </div>
                            </div>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.dateOfBirthOrEndOfPregnancy`}
                              validators={{
                                onBlur: ({ value }: { value: string }) => {
                                  if (!value) return null;
                                  if (
                                    value.length > 1 &&
                                    !dayjs(value).isValid()
                                  ) {
                                    return 'Invalid date';
                                  }
                                  return null;
                                },
                              }}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Label className="mb-2">
                                    Date of birth or end of pregnancy
                                  </Label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <InputMask
                                      component={Input}
                                      name={field.name}
                                      mask="MM/DD/YYYY"
                                      separate
                                      showMask
                                      replacement={{
                                        D: /\d/,
                                        M: /\d/,
                                        Y: /\d/,
                                      }}
                                      value={subField.state.value as string}
                                      onChange={(event) => {
                                        const value =
                                          event.target.value === 'MM/DD/YYYY'
                                            ? ''
                                            : event.target.value;
                                        subField.handleChange(
                                          value ?? undefined
                                        );
                                      }}
                                      onBlur={(evt) => {
                                        subField.handleBlur();
                                        if (
                                          (dayjs(evt.target.value).isValid() ||
                                            evt.target.value === '') &&
                                          subField.state.meta.isDirty
                                        ) {
                                          obstetricHistoryInfoForm.handleSubmit();
                                        }
                                      }}
                                      disabled={readonly}
                                    />
                                  </LocalizationProvider>
                                  <FormError id={`err-${subField.name}`}>
                                    {subField.state.meta.errors?.join('\r')}
                                  </FormError>
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.amountOfGestationWeeks`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Label className="mb-2">
                                    How many weeks in gestation?
                                  </Label>
                                  <Input
                                    placeholder="Weeks"
                                    type="number"
                                    value={
                                      subField.state.value === 0
                                        ? ''
                                        : subField.state.value
                                    }
                                    onChange={(event) => {
                                      const numberValue = Number(
                                        event.target.value
                                      );
                                      if (
                                        numberValue > -1 &&
                                        numberValue <= 52
                                      ) {
                                        subField.handleChange(numberValue);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (subField.state.meta.isDirty) {
                                        obstetricHistoryInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.otherPregnancyOutcomes`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Label className="mb-3">
                                    During this pregnancy or delivery did you
                                    experience any of the following?
                                  </Label>
                                  <div className="flex flex-wrap gap-2">
                                    <div className="flex flex-col">
                                      {defaultOtherPregnancyOutcomes
                                        .slice(0, 10)
                                        .map((outcome) => (
                                          <div className="flex gap-2 relative">
                                            <Checkbox
                                              id={`${subField.name}.${outcome.value}`}
                                              key={outcome.value}
                                              checked={
                                                subField.state.value?.find(
                                                  (item) =>
                                                    item.value === outcome.value
                                                )?.checked
                                              }
                                              onCheckedChange={(value) => {
                                                if (value === 'indeterminate')
                                                  return;
                                                const updatedItems =
                                                  subField.state.value?.map(
                                                    (item) =>
                                                      item.value ===
                                                      outcome.value
                                                        ? {
                                                            ...item,
                                                            checked: value,
                                                          }
                                                        : item
                                                  );
                                                if (updatedItems) {
                                                  subField.handleChange(
                                                    updatedItems
                                                  );
                                                  obstetricHistoryInfoForm.handleSubmit();
                                                }
                                              }}
                                              disabled={readonly}
                                            />
                                            <Label
                                              htmlFor={`${subField.name}.${outcome.value}`}
                                              className="cursor-pointe text-aster-secondary font-normal"
                                            >
                                              {outcome.text}
                                            </Label>
                                          </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                      {defaultOtherPregnancyOutcomes
                                        .slice(10)
                                        .map((outcome) => (
                                          <div className="flex gap-2 relative">
                                            <Checkbox
                                              id={`${subField.name}.${outcome.value}`}
                                              key={outcome.value}
                                              checked={
                                                subField.state.value?.find(
                                                  (item) =>
                                                    item.value === outcome.value
                                                )?.checked
                                              }
                                              onCheckedChange={(value) => {
                                                if (value === 'indeterminate')
                                                  return;
                                                const updatedItems =
                                                  subField.state.value?.map(
                                                    (item) =>
                                                      item.value ===
                                                      outcome.value
                                                        ? {
                                                            ...item,
                                                            checked: value,
                                                          }
                                                        : item
                                                  );
                                                if (updatedItems) {
                                                  subField.handleChange(
                                                    updatedItems
                                                  );
                                                  obstetricHistoryInfoForm.handleSubmit();
                                                }
                                              }}
                                              disabled={readonly}
                                            />
                                            <Label
                                              htmlFor={`${subField.name}.${outcome.value}`}
                                              className="cursor-pointer text-aster-secondary font-normal"
                                            >
                                              {outcome.text}
                                            </Label>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.complications`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Input
                                    placeholder="Other complications"
                                    value={subField.state.value}
                                    onChange={(event) =>
                                      subField.handleChange(event.target.value)
                                    }
                                    onBlur={() => {
                                      if (subField.state.meta.isDirty) {
                                        obstetricHistoryInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.locationOfDelivery`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Label className="mb-3">
                                    What was the location of the delivery?
                                  </Label>
                                  <RadioGroup
                                    className="flex flex-col items-start gap-3"
                                    value={
                                      subField.state
                                        .value as PregnancyInfoDTO['locationOfDelivery']
                                    }
                                    onValueChange={(value) => {
                                      subField.handleChange(
                                        value as PregnancyInfoDTO['locationOfDelivery']
                                      );
                                      obstetricHistoryInfoForm.handleSubmit();
                                    }}
                                  >
                                    {defaultPregnanancyLocationOfDelivery.map(
                                      (location) => (
                                        <div className="flex gap-2 items-center">
                                          <RadioItem
                                            className="shrink-0"
                                            id={`${subField.name}-${location.value}`}
                                            value={location.value}
                                            disabled={readonly}
                                          >
                                            <RadioIndicator />
                                          </RadioItem>
                                          <Label
                                            htmlFor={`${subField.name}-${location.value}`}
                                            className="m-0 cursor-pointer text-aster-secondary font-normal"
                                          >
                                            {location.text}
                                          </Label>
                                        </div>
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <obstetricHistoryInfoForm.Field
                              name={`pregnancies[${index}].pregnancy.locationOfDeliveryOther`}
                            >
                              {(subField) => (
                                <FormControl>
                                  <Input
                                    placeholder="Other location of delivery"
                                    value={subField.state.value}
                                    onChange={(event) => {
                                      subField.handleChange(event.target.value);
                                    }}
                                    onBlur={() => {
                                      if (subField.state.meta.isDirty) {
                                        obstetricHistoryInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                </FormControl>
                              )}
                            </obstetricHistoryInfoForm.Field>
                            <>
                              <Typography variant={'h6'} customClass="my-2">
                                Children in this pregnancy
                              </Typography>
                              {_.children.map((child, j) => (
                                <div
                                  key={j}
                                  className="flex flex-col gap-y-5 border-l-4 border-aster-main pl-6"
                                >
                                  <div className="flex justify-between">
                                    <Typography
                                      customClass="my-3"
                                      variant={'h6'}
                                    >
                                      Child {j + 1}
                                    </Typography>
                                    <Button
                                      type="button"
                                      variant={'default'}
                                      size={'sm'}
                                      className="text-bodyMedium font-medium bg-gray-100 text-black-500 hover:bg-gray-200"
                                      onClick={() => {
                                        const updatedPregnancies = [
                                          ...field.state.value,
                                        ];
                                        updatedPregnancies[
                                          index
                                        ].children.splice(j, 1);
                                        field.handleChange(updatedPregnancies);
                                      }}
                                      disabled={
                                        readonly || _.children.length === 1
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                      Remove
                                    </Button>
                                  </div>
                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].isLiving`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Label className="mb-3">
                                          Is this child currently living?
                                        </Label>
                                        <RadioGroup
                                          className="flex flex-col items-start gap-3"
                                          value={
                                            subField.state.value === true
                                              ? 'yes'
                                              : subField.state.value === false
                                              ? 'no'
                                              : undefined
                                          }
                                          onValueChange={(value) => {
                                            const isLiving =
                                              value === 'yes' ? true : false;
                                            subField.handleChange(isLiving);
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }}
                                        >
                                          <div className="flex gap-2 items-center">
                                            <RadioItem
                                              className="shrink-0"
                                              id={`${subField.name}-yes`}
                                              value={'yes'}
                                              disabled={readonly}
                                            >
                                              <RadioIndicator />
                                            </RadioItem>
                                            <Label
                                              htmlFor={`${subField.name}-yes`}
                                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                                            >
                                              Yes
                                            </Label>
                                          </div>
                                          <div className="flex gap-2 items-center">
                                            <RadioItem
                                              className="shrink-0"
                                              id={`${subField.name}-no`}
                                              value={'no'}
                                              disabled={readonly}
                                            >
                                              <RadioIndicator />
                                            </RadioItem>
                                            <Label
                                              htmlFor={`${subField.name}-no`}
                                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                                            >
                                              No
                                            </Label>
                                          </div>
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>
                                  <div className="flex flex-wrap gap-4">
                                    <obstetricHistoryInfoForm.Field
                                      name={`pregnancies[${index}].children[${j}].weightUnit`}
                                    >
                                      {(subField) => (
                                        <FormControl>
                                          <Label className="mb-2">
                                            Weight Unit
                                          </Label>
                                          <Select
                                            value={subField.state.value ?? ''}
                                            defaultValue={
                                              subField.state.value ?? ''
                                            }
                                            onValueChange={(value) => {
                                              subField.handleChange(
                                                value as ChildInfoDTO['weightUnit']
                                              );
                                              obstetricHistoryInfoForm.handleSubmit();
                                            }}
                                            disabled={readonly}
                                          >
                                            <SelectTrigger className="bg-white">
                                              <SelectValue
                                                placeholder={'Select Unit'}
                                              />
                                            </SelectTrigger>
                                            <SelectContent>
                                              <SelectGroup>
                                                <SelectItem value="lbs">
                                                  Lbs
                                                </SelectItem>
                                                <SelectItem value="g">
                                                  Grams
                                                </SelectItem>
                                              </SelectGroup>
                                            </SelectContent>
                                          </Select>
                                        </FormControl>
                                      )}
                                    </obstetricHistoryInfoForm.Field>
                                    {obstetricHistoryInfoForm.state.values
                                      .pregnancies[index].children[j]
                                      .weightUnit === 'lbs' && (
                                      <div className="flex gap-4">
                                        <obstetricHistoryInfoForm.Field
                                          name={`pregnancies[${index}].children[${j}].pounds`}
                                        >
                                          {(subField) => (
                                            <FormControl>
                                              <Label className="mb-2">
                                                Pounds
                                              </Label>
                                              <Input
                                                placeholder="Pounds"
                                                type="number"
                                                value={
                                                  subField.state.value === 0
                                                    ? ''
                                                    : subField.state.value
                                                }
                                                onChange={(event) => {
                                                  const numberValue = Number(
                                                    event.target.value
                                                  );
                                                  if (numberValue > -1) {
                                                    subField.handleChange(
                                                      numberValue
                                                    );
                                                  }
                                                }}
                                                onBlur={() => {
                                                  if (
                                                    subField.state.meta.isDirty
                                                  ) {
                                                    obstetricHistoryInfoForm.handleSubmit();
                                                  }
                                                }}
                                                disabled={readonly}
                                              />
                                            </FormControl>
                                          )}
                                        </obstetricHistoryInfoForm.Field>
                                        <obstetricHistoryInfoForm.Field
                                          name={`pregnancies[${index}].children[${j}].ounces`}
                                        >
                                          {(subField) => (
                                            <FormControl>
                                              <Label className="mb-2">
                                                Ounces
                                              </Label>
                                              <Input
                                                placeholder="Ounces"
                                                type="number"
                                                value={
                                                  subField.state.value === 0
                                                    ? ''
                                                    : subField.state.value
                                                }
                                                onChange={(event) => {
                                                  const numberValue = Number(
                                                    event.target.value
                                                  );
                                                  if (numberValue > -1) {
                                                    subField.handleChange(
                                                      numberValue
                                                    );
                                                  }
                                                }}
                                                onBlur={() => {
                                                  if (
                                                    subField.state.meta.isDirty
                                                  ) {
                                                    obstetricHistoryInfoForm.handleSubmit();
                                                  }
                                                }}
                                                disabled={readonly}
                                              />
                                            </FormControl>
                                          )}
                                        </obstetricHistoryInfoForm.Field>
                                      </div>
                                    )}
                                    {obstetricHistoryInfoForm.state.values
                                      .pregnancies[index].children[j]
                                      .weightUnit === 'g' && (
                                      <obstetricHistoryInfoForm.Field
                                        name={`pregnancies[${index}].children[${j}].grams`}
                                      >
                                        {(subField) => (
                                          <FormControl>
                                            <Label className="mb-2">
                                              Grams
                                            </Label>
                                            <Input
                                              placeholder="Grams"
                                              type="number"
                                              value={
                                                subField.state.value === 0
                                                  ? ''
                                                  : subField.state.value
                                              }
                                              onChange={(event) => {
                                                const numberValue = Number(
                                                  event.target.value
                                                );
                                                if (numberValue > -1) {
                                                  subField.handleChange(
                                                    numberValue
                                                  );
                                                }
                                              }}
                                              onBlur={() => {
                                                if (
                                                  subField.state.meta.isDirty
                                                ) {
                                                  obstetricHistoryInfoForm.handleSubmit();
                                                }
                                              }}
                                              disabled={readonly}
                                            />
                                          </FormControl>
                                        )}
                                      </obstetricHistoryInfoForm.Field>
                                    )}
                                  </div>
                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].name`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Label className="mb-2">
                                          Child's Name
                                        </Label>
                                        <Input
                                          placeholder="Name"
                                          value={subField.state.value}
                                          onChange={(event) => {
                                            subField.handleChange(
                                              event.target.value
                                            );
                                          }}
                                          onBlur={() => {
                                            if (subField.state.meta.isDirty) {
                                              obstetricHistoryInfoForm.handleSubmit();
                                            }
                                          }}
                                          disabled={readonly}
                                        />
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>

                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].sex`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Label className="mb-3">
                                          Child's Sex
                                        </Label>
                                        <RadioGroup
                                          className="flex flex-col items-start gap-3"
                                          value={
                                            subField.state
                                              .value as ChildInfoDTO['sex']
                                          }
                                          onValueChange={(value) => {
                                            subField.handleChange(
                                              value as ChildInfoDTO['sex']
                                            );
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }}
                                        >
                                          {defaultChildSexOptions.map((sex) => (
                                            <div className="flex gap-2 items-center">
                                              <RadioItem
                                                className="shrink-0"
                                                id={`${subField.name}-${sex.value}`}
                                                value={sex.value}
                                                disabled={readonly}
                                              >
                                                <RadioIndicator />
                                              </RadioItem>
                                              <Label
                                                htmlFor={`${subField.name}-${sex.value}`}
                                                className="m-0 cursor-pointer text-aster-secondary font-normal"
                                              >
                                                {sex.text}
                                              </Label>
                                            </div>
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>
                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].otherSex`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Input
                                          placeholder="Other child sex details"
                                          value={subField.state.value}
                                          onChange={(event) => {
                                            subField.handleChange(
                                              event.target.value
                                            );
                                          }}
                                          onBlur={() => {
                                            if (subField.state.meta.isDirty) {
                                              obstetricHistoryInfoForm.handleSubmit();
                                            }
                                          }}
                                          disabled={readonly}
                                        />
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>
                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].feedingCourse`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Label className="mb-3">
                                          Feeding course
                                        </Label>
                                        <RadioGroup
                                          className="flex flex-col items-start gap-3"
                                          value={
                                            subField.state
                                              .value as ChildInfoDTO['feedingCourse']
                                          }
                                          onValueChange={(value) => {
                                            subField.handleChange(
                                              value as ChildInfoDTO['feedingCourse']
                                            );
                                            obstetricHistoryInfoForm.handleSubmit();
                                          }}
                                        >
                                          {defaultFeedingCourseOptions.map(
                                            (outcome) => (
                                              <div className="flex gap-2 items-center">
                                                <RadioItem
                                                  className="shrink-0"
                                                  id={`${subField.name}-${outcome.value}`}
                                                  value={outcome.value}
                                                  disabled={readonly}
                                                >
                                                  <RadioIndicator />
                                                </RadioItem>
                                                <Label
                                                  htmlFor={`${subField.name}-${outcome.value}`}
                                                  className="m-0 cursor-pointer text-aster-secondary font-normal"
                                                >
                                                  {outcome.text}
                                                </Label>
                                              </div>
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>
                                  {obstetricHistoryInfoForm.state.values
                                    .pregnancies[index].children[j]
                                    .feedingCourse &&
                                    ['breast', 'both'].includes(
                                      obstetricHistoryInfoForm.state.values
                                        .pregnancies[index].children[j]
                                        .feedingCourse
                                    ) && (
                                      <obstetricHistoryInfoForm.Field
                                        name={`pregnancies[${index}].children[${j}].breastFeedDuration`}
                                      >
                                        {(subField) => (
                                          <FormControl>
                                            <Input
                                              placeholder="Months of breastfeeding"
                                              type="number"
                                              value={
                                                subField.state.value === 0
                                                  ? ''
                                                  : subField.state.value
                                              }
                                              onChange={(event) => {
                                                const numberValue = Number(
                                                  event.target.value
                                                );
                                                if (numberValue > -1) {
                                                  subField.handleChange(
                                                    numberValue
                                                  );
                                                }
                                              }}
                                              onBlur={() => {
                                                if (
                                                  subField.state.meta.isDirty
                                                ) {
                                                  obstetricHistoryInfoForm.handleSubmit();
                                                }
                                              }}
                                              disabled={readonly}
                                            />
                                          </FormControl>
                                        )}
                                      </obstetricHistoryInfoForm.Field>
                                    )}
                                  <obstetricHistoryInfoForm.Field
                                    name={`pregnancies[${index}].children[${j}].breastFeedDetails`}
                                  >
                                    {(subField) => (
                                      <FormControl>
                                        <Input
                                          placeholder="Other feeding details"
                                          value={subField.state.value}
                                          onChange={(event) => {
                                            subField.handleChange(
                                              event.target.value
                                            );
                                          }}
                                          onBlur={() => {
                                            if (subField.state.meta.isDirty) {
                                              obstetricHistoryInfoForm.handleSubmit();
                                            }
                                          }}
                                          disabled={readonly}
                                        />
                                      </FormControl>
                                    )}
                                  </obstetricHistoryInfoForm.Field>
                                </div>
                              ))}
                              <div>
                                <Button
                                  size={'sm'}
                                  type="button"
                                  className="mt-1 text-bodyMedium bg-gray-100 text-black-500 hover:bg-gray-200"
                                  variant={'default'}
                                  onClick={() => {
                                    const updatedPregnancies = [
                                      ...field.state.value,
                                    ];
                                    updatedPregnancies[index].children.push({
                                      ...defaultPregnancyInfo.children[0],
                                    });
                                    field.handleChange(updatedPregnancies);
                                    obstetricHistoryInfoForm.handleSubmit();
                                  }}
                                >
                                  + Add Child
                                </Button>
                              </div>
                            </>
                          </div>
                        ))}
                        <div>
                          <Button
                            size={'sm'}
                            type="button"
                            className="mt-1 text-bodyMedium bg-gray-100 text-black-500 hover:bg-gray-200"
                            variant={'default'}
                            onClick={() => {
                              const updatedPregnancies = [
                                ...field.state.value,
                                {
                                  pregnancy: {
                                    ...defaultPregnancyInfo.pregnancy,
                                    dateOfBirthOrEndOfPregnancy: undefined,
                                    patientID,
                                    otherPregnancyOutcomes:
                                      defaultOtherPregnancyOutcomes,
                                  },
                                  children: defaultPregnancyInfo.children,
                                },
                              ];
                              field.handleChange(updatedPregnancies);
                              obstetricHistoryInfoForm.handleSubmit();
                            }}
                          >
                            + Add Pregnancy
                          </Button>
                        </div>
                      </>
                    )}
                  </obstetricHistoryInfoForm.Field>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default ObstetricInformation;
