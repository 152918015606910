import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CircularProgress } from '@mui/material';
import Typography from '../../../components/Typography';
import {
  PregnancyInfoFields,
  usePregnancyInfoForm,
} from '../hooks/usePregnancyInfoForm';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { InputMask } from '@react-input/mask';
import { Input } from '@aster/client/ui/Input/Input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { LAST_SAVED_PREGNANCY_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle } from 'react';

const PregnancyInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit: () => {
        pregnancyInfoForm.handleSubmit();
      },
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_PREGNANCY_INFORMATION);
    const lastSavedString = lastSaved
      ? `Last saved 
  ${dayjs().diff(dayjs(lastSaved), 'minutes')}
   minutes ago`
      : '';

    const pregnancyInfoForm = usePregnancyInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_PREGNANCY_INFORMATION,
            dayjs().toISOString()
          );
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<PregnancyInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      1000
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={pregnancyInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                pregnancyInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Pregnancy Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <pregnancyInfoForm.Field
                    name="pregnant"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Are you currently pregnant?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasFamilyMedicalConditions = value === 'yes';
                            field.handleChange(hasFamilyMedicalConditions);
                            pregnancyInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {pregnancyInfoForm.state.values.pregnant && (
                    <div className="flex flex-col gap-y-5">
                      <Typography
                        text="Personal Information"
                        variant="h5"
                        customClass="font-semibold"
                      ></Typography>
                      <div className="flex flex-wrap gap-4">
                        <pregnancyInfoForm.Field
                          name="lmp"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl>
                              <Label>Last Menstrual Period (LMP)</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                        <pregnancyInfoForm.Field
                          name="edd"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl>
                              <Label>Estimated Due Date (EDD)</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                      </div>
                      <div className="flex flex-wrap gap-4">
                        <pregnancyInfoForm.Field
                          name="dateOfConception"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl>
                              <Label>Date of Conception</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                        <pregnancyInfoForm.Field
                          name="dateToCalculateDueDate"
                          children={(field) => (
                            <FormControl>
                              <Label>Date to calculate due date</Label>
                              <Select
                                value={field.state.value ?? ''}
                                onValueChange={(value) => {
                                  field.handleChange(
                                    value as PatientInfoDTO['dateToCalculateDueDate']
                                  );
                                  pregnancyInfoForm.handleSubmit();
                                }}
                                disabled={readonly}
                              >
                                <SelectTrigger className="bg-white">
                                  <SelectValue placeholder={'Select Date'} />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="edd">
                                      Estimated Due Date (EDD)
                                    </SelectItem>
                                    <SelectItem value="lmp">
                                      Last Menstrual Period (LMP)
                                    </SelectItem>
                                    <SelectItem value="doudateOfConceptionla">
                                      Date of conception
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </div>
                      <pregnancyInfoForm.Field
                        name="unsureDueDate"
                        children={(field) => (
                          <FormControl className="flex flex-row items-center gap-2 relative">
                            <Checkbox
                              id={field.name}
                              checked={field.state.value as boolean}
                              onCheckedChange={(value) => {
                                field.handleChange(value as boolean);
                                pregnancyInfoForm.handleSubmit();
                              }}
                              disabled={readonly}
                            />
                            <Label
                              htmlFor={field.name}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Unsure due date?
                            </Label>
                          </FormControl>
                        )}
                      />
                    </div>
                  )}
                  <pregnancyInfoForm.Field
                    name="hadUltrasounds"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you had any ultrasound scans?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasFamilyMedicalConditions = value === 'yes';
                            field.handleChange(hasFamilyMedicalConditions);
                            pregnancyInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {pregnancyInfoForm.state.values.hadUltrasounds && (
                    <pregnancyInfoForm.Field
                      name="ultraSoundDetails"
                      children={(field) => (
                        <FormControl>
                          <Label>Ultrasound details</Label>
                          <Input
                            placeholder="Enter ultrasound details"
                            id={field.name}
                            name={field.name}
                            value={field.state.value as string}
                            onChange={(event) => {
                              field.handleChange(event.target.value);
                            }}
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                pregnancyInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default PregnancyInformation;
