import Typography from '../../../components/Typography';
import {
  LifestyleInfoFields,
  useLifestyleInfoForm,
} from '../hooks/useLifestyleInfoForm';
import { CircularProgress, Radio } from '@mui/material';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { Label } from '@aster/client/ui/Label/Label';
import { Input } from '@aster/client/ui/Input/Input';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { defaultRegularExerciseType } from '../defaults';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import dayjs from 'dayjs';
import { LAST_SAVED_LIFESTYLE_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle } from 'react';

const Lifestyle = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit: () => {
        lifestyleInfoForm.handleSubmit();
      },
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_LIFESTYLE_INFORMATION);
    const lastSavedString = lastSaved
      ? `Last saved 
    ${dayjs().diff(dayjs(lastSaved), 'minutes')}
     minutes ago`
      : '';

    const lifestyleInfoForm = useLifestyleInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_LIFESTYLE_INFORMATION,
            dayjs().toISOString()
          );
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<LifestyleInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      1000
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={lifestyleInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                lifestyleInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Lifestyle"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <lifestyleInfoForm.Field
                    name="usesTobacco"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you smoke cigarettes or use tobacco products?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === 'no'
                              ? 'no'
                              : field.state.value === 'quit-1-plus-year-ago'
                              ? 'quit-1-plus-year-ago'
                              : field.state.value === 'quite-under-1-year-ago'
                              ? 'quite-under-1-year-ago'
                              : field.state.value === 'yes'
                              ? 'yes'
                              : ''
                          }
                          onValueChange={(value) => {
                            field.handleChange(
                              value as
                                | 'yes'
                                | 'no'
                                | 'quit-1-plus-year-ago'
                                | 'quite-under-1-year-ago'
                            );
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-quit-1-plus-year-ago`}
                              value="quit-1-plus-year-ago"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-quit-1-plus-year-ago`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Quit 1+ year ago
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-quite-under-1-year-ago`}
                              value="quite-under-1-year-ago"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-quite-under-1-year-ago`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Quit under 1 year ago
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {lifestyleInfoForm.state.values.usesTobacco === 'yes' && (
                    <lifestyleInfoForm.Field
                      name="tobaccoOrCigarettesPerDay"
                      children={(field) => (
                        <FormControl>
                          <Label>qty/day</Label>
                          <Input
                            type="number"
                            name="tobaccoOrCigarettesPerDay"
                            value={
                              field.state.value === 0
                                ? ''
                                : (field.state.value as number)
                            }
                            onChange={(evt) => {
                              const value = Number(evt.target.value);
                              if (value > -1 && Number.isInteger(value)) {
                                field.handleChange(Number(evt.target.value));
                              }
                              lifestyleInfoForm.handleSubmit();
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  <lifestyleInfoForm.Field
                    name="consumesAlcohol"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">Do you drink alcohol?</Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === false
                              ? 'no'
                              : field.state.value === true
                              ? 'yes'
                              : ''
                          }
                          onValueChange={(value) => {
                            const consumesAlcohol = value === 'yes';
                            field.handleChange(consumesAlcohol);
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {lifestyleInfoForm.state.values.consumesAlcohol && (
                    <lifestyleInfoForm.Field
                      name="alcoholUseDetails"
                      children={(field) => (
                        <FormControl>
                          <Label>drinks/day</Label>
                          <Input
                            className="max-w-[250px]"
                            type="number"
                            name="alcoholUseDetails"
                            value={field.state.value ?? ''}
                            onChange={(evt) =>
                              field.handleChange(evt.target.value)
                            }
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                lifestyleInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  <lifestyleInfoForm.Field
                    name="consumesRecreationalDrugs"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you use recreational drugs?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === false
                              ? 'no'
                              : field.state.value === true
                              ? 'yes'
                              : ''
                          }
                          onValueChange={(value) => {
                            const consumesRecreationalDrugs = value === 'yes';
                            field.handleChange(consumesRecreationalDrugs);
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {lifestyleInfoForm.state.values.consumesRecreationalDrugs && (
                    <lifestyleInfoForm.Field
                      name="recreationalDrugUseDetails"
                      children={(field) => (
                        <FormControl>
                          <Label>Details</Label>
                          <Input
                            className="max-w-[250px]"
                            type="text"
                            name="recreationalDrugUseDetails"
                            value={field.state.value ?? ''}
                            onChange={(evt) =>
                              field.handleChange(evt.target.value)
                            }
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                lifestyleInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  <lifestyleInfoForm.Field
                    name="hasHealthyDiet"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you consider yourself to have a healthy balanced
                          diet?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === 'no'
                              ? 'no'
                              : field.state.value === 'somewhat'
                              ? 'somewhat'
                              : field.state.value === 'mostly'
                              ? 'mostly'
                              : field.state.value === 'yes'
                              ? 'yes'
                              : ''
                          }
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'no' | 'somewhat' | 'mostly' | 'yes'
                            );
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-mostly`}
                              value="mostly"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-mostly`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Mostly
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <lifestyleInfoForm.Field
                    name="practiceRegularExercise"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you engage in regular physical exercise?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === false
                              ? 'no'
                              : field.state.value === true
                              ? 'yes'
                              : ''
                          }
                          onValueChange={(value) => {
                            const practiceRegularExercise = value === 'yes';
                            field.handleChange(practiceRegularExercise);
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {lifestyleInfoForm.state.values.practiceRegularExercise && (
                    <>
                      <lifestyleInfoForm.Field
                        name="regularExerciseHoursPerWeek"
                        children={(field) => (
                          <FormControl>
                            <Label>Hrs/Week</Label>
                            <Input
                              className="max-w-[250px]"
                              type="number"
                              name="regularExerciseHoursPerWeek"
                              value={
                                field.state.value === 0
                                  ? ''
                                  : (field.state.value as number)
                              }
                              onChange={(evt) => {
                                const value = Number(evt.target.value);
                                if (value > -1 && Number.isInteger(value)) {
                                  field.handleChange(Number(evt.target.value));
                                }
                              }}
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  lifestyleInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                            />
                          </FormControl>
                        )}
                      />
                      <div className="flex flex-row">
                        <lifestyleInfoForm.Field
                          name="regularExerciseType"
                          children={(field) => (
                            <FormControl className="flex flex-col gap-2">
                              <Label>What type of exercise?</Label>
                              {defaultRegularExerciseType.map((exercise) => (
                                <div
                                  key={exercise.value}
                                  className="flex gap-2 relative"
                                >
                                  <Checkbox
                                    id={field.name}
                                    key={exercise.value}
                                    checked={
                                      field.state.value?.find(
                                        (item) => item.value === exercise.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map((item) =>
                                          item.value === exercise.value
                                            ? { ...item, checked: value }
                                            : item
                                        );
                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        lifestyleInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    className="cursor-pointer text-aster-secondary font-normal"
                                    htmlFor={field.name}
                                  >
                                    {exercise.text}
                                  </Label>
                                </div>
                              ))}
                            </FormControl>
                          )}
                        />
                      </div>
                    </>
                  )}
                  <lifestyleInfoForm.Field
                    name="hasDieataryRequirements"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any dietary requirements?
                        </Label>
                        <RadioGroup
                          className="flex flex-col items-start gap-2"
                          value={
                            field.state.value === 'glucose_intolerance'
                              ? 'glucose_intolerance'
                              : field.state.value === 'vegetarian'
                              ? 'vegetarian'
                              : field.state.value === 'vegan'
                              ? 'vegan'
                              : field.state.value === 'lactose_intolerance'
                              ? 'lactose_intolerance'
                              : field.state.value === 'pescatarian'
                              ? 'pescatarian'
                              : field.state.value === 'other'
                              ? 'other'
                              : field.state.value === 'na'
                              ? 'na'
                              : ''
                          }
                          onValueChange={(value) => {
                            field.handleChange(value as string);
                            lifestyleInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-glucose_intolerance`}
                              value="glucose_intolerance"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-glucose_intolerance`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Gluten intolerance
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-vegetarian`}
                              value="vegetarian"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-vegetarian`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Vegetarian
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-vegan`} value="vegan">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-vegan`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Vegan
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-lactose_intolerance`}
                              value="lactose_intolerance"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-lactose_intolerance`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Lactose intolerance
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-pescatarian`}
                              value="pescatarian"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-pescatarian`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Pescatarian
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-other`} value="other">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-other`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Other
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <lifestyleInfoForm.Field
                    name="dietaryRequirementsDetailsText"
                    children={(field) => (
                      <FormControl>
                        <Input
                          placeholder="Other dietary requirements"
                          className="max-w-[250px]"
                          type="text"
                          name="dietaryRequirementsDetailsText"
                          value={field.state.value ?? ''}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              lifestyleInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default Lifestyle;
