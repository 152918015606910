import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { PatientInfoDTO, PatientInsurance } from '@aster/shared/dtos/patient';
import { InsuranceInfo } from '../types/patient-profile';

export const useInsuranceInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: {
    insurances: Partial<PatientInsurance>[];
    cashPayOnly: boolean;
  }) => void;
}) => {
  const { insurances } = defaultValues ?? {};

  const insuranceInfoForm = useForm({
    defaultValues: {
      insuranceFirstProvider: insurances?.[0]?.insuranceProvider ?? '',
      insuranceFirstNumber: insurances?.[0]?.insuranceNumber ?? '',
      insuranceFirstGroupId: insurances?.[0]?.insuranceGroupId ?? '',
      useFirstForBaby: insurances?.[0]?.useForBaby ?? false,
      insuranceSecondProvider: insurances?.[1]?.insuranceProvider ?? '',
      insuranceSecondNumber: insurances?.[1]?.insuranceNumber ?? '',
      insuranceSecondGroupId: insurances?.[1]?.insuranceGroupId ?? '',
      useSecondForBaby: insurances?.[1]?.useForBaby ?? false,
      cashOnly: defaultValues?.cashPayOnly ?? false,
    } as InsuranceInfo,
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const insurances = [
        {
          insuranceProvider: value.insuranceFirstProvider ?? '',
          insuranceNumber: value.insuranceFirstNumber ?? '',
          insuranceGroupId: value.insuranceFirstGroupId ?? '',
          useForBaby: value.useFirstForBaby ?? false,
        },
        {
          insuranceProvider: value.insuranceSecondProvider ?? '',
          insuranceNumber: value.insuranceSecondNumber ?? '',
          insuranceGroupId: value.insuranceSecondGroupId ?? '',
          useForBaby: value.useSecondForBaby ?? false,
        },
      ];
      onSubmit({ insurances, cashPayOnly: value.cashOnly });
    },
  });
  return insuranceInfoForm;
};
