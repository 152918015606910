import { useForm } from '@tanstack/react-form';
import { PersonalInfo } from '../types/patient-profile';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import dayjs from 'dayjs';

export type PersonalInfoFields = Pick<
  PatientInfoDTO,
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'dateOfBirth'
  | 'legalSex'
  | 'ethnicity'
  | 'race'
  | 'preferredLanguage'
  | 'preferredName'
  | 'pronouns'
  | 'genderIdentity'
>;

export const usePersonalInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<PersonalInfoFields>) => void;
}) => {
  const personalInfoForm = useForm({
    defaultValues: {
      firstName: defaultValues?.firstName ?? undefined,
      middleName: defaultValues?.middleName ?? undefined,
      lastName: defaultValues?.lastName ?? undefined,
      dateOfBirth: defaultValues?.dateOfBirth
        ? dayjs(defaultValues.dateOfBirth).format('MM/DD/YYYY')
        : '',
      legalSex: defaultValues?.legalSex ?? undefined,
      ethnicity: defaultValues?.ethnicity ?? undefined,
      race: defaultValues?.race ?? undefined,
      preferredLanguage: defaultValues?.preferredLanguage ?? undefined,
      preferredName: defaultValues?.preferredName ?? undefined,
      pronouns: defaultValues?.pronouns ?? undefined,
      genderIdentity: defaultValues?.genderIdentity ?? undefined,
    } as PersonalInfo,
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      value.dateOfBirth = value.dateOfBirth === '' ? null : value.dateOfBirth;
      onSubmit(value);
    },
  });

  return personalInfoForm;
};
