import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import dayjs from 'dayjs';
import { defaultOtherPregnancyOutcomes } from '../defaults';

export const useObstetricHistoryInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: PatientInfoDTO['pregnancies']) => void;
}) => {
  const obstetricHistoryInfoForm = useForm({
    defaultValues: {
      pregnancies: defaultValues?.pregnancies
        ? defaultValues.pregnancies.map((item) => {
            const { pregnancy, children } = item;
            return {
              pregnancy: {
                ...pregnancy,
                dateOfBirthOrEndOfPregnancy:
                  pregnancy.dateOfBirthOrEndOfPregnancy
                    ? dayjs(pregnancy.dateOfBirthOrEndOfPregnancy).format(
                        'MM/DD/YYYY'
                      )
                    : undefined,
                otherPregnancyOutcomes: defaultOtherPregnancyOutcomes.map(
                  (outcome) => {
                    return {
                      ...outcome,
                      checked:
                        pregnancy.otherPregnancyOutcomes?.find(
                          (item) => item.value === outcome.value
                        )?.checked ?? false,
                    };
                  }
                ),
              },
              children,
            };
          })
        : [],
    },

    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value.pregnancies);
    },
  });
  return obstetricHistoryInfoForm;
};
