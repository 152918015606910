import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import {
  defaultBloodBorneIllnessDetails,
  defaultChronicMedicalConditions,
  defaultCurrentMentalHealthProblemOrDiagnosisDetails,
  defaultFamilyMedicalConditions,
} from '../defaults';
import { it } from 'node:test';

export type MedicalHistoryFields = Pick<
  PatientInfoDTO,
  | 'hasChronicMedicalConditions'
  | 'chronicMedicalConditions'
  | 'chronicMedicalConditionsDetailsText'
  | 'hasCurrentMentalHealthProblemOrDiagnosis'
  | 'currentMentalHealthProblemOrDiagnosisDetails'
  | 'currentMentalHealthProblemOrDiagnosisDetailsText'
  | 'hasBloodBorneIllness'
  | 'bloodBorneIllnessDetails'
  | 'bloodBorneIllnessDetailsText'
  | 'hasPreviousSurgery'
  | 'hasPreviousSurgeryDetails'
  | 'hasPreviousAbdominalSurgery'
  | 'abdominalSurgeryDetails'
  | 'hasMajorAccidentsOrInjuries'
  | 'majorAccidentsOrInjuriesDetails'
  | 'isCurrentlyTakingMedicationsOrSuplements'
  | 'currentMedicationsOrSupplements'
  | 'hasAllergies'
  | 'allergyList'
  | 'hasFamilyMedicalConditions'
  | 'familyMedicalConditions'
  | 'familyMedicalConditionsDetailsText'
>;

export const useMedicalHistoryInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<MedicalHistoryFields>) => void;
}) => {
  const medicalHistoryInfoForm = useForm({
    defaultValues: {
      hasChronicMedicalConditions:
        defaultValues?.hasChronicMedicalConditions ?? null,
      chronicMedicalConditions: defaultChronicMedicalConditions.map(
        (condition) => {
          return {
            ...condition,
            checked:
              defaultValues?.chronicMedicalConditions?.find(
                (item) => item.value === condition.value
              )?.checked ?? false,
          };
        }
      ),
      chronicMedicalConditionsDetailsText:
        defaultValues?.chronicMedicalConditionsDetailsText ?? null,
      hasCurrentMentalHealthProblemOrDiagnosis:
        defaultValues?.hasCurrentMentalHealthProblemOrDiagnosis ?? null,
      currentMentalHealthProblemOrDiagnosisDetails:
        defaultCurrentMentalHealthProblemOrDiagnosisDetails.map((condition) => {
          return {
            ...condition,
            checked:
              defaultValues?.currentMentalHealthProblemOrDiagnosisDetails?.find(
                (item) => item.value === condition.value
              )?.checked ?? false,
          };
        }),
      currentMentalHealthProblemOrDiagnosisDetailsText:
        defaultValues?.currentMentalHealthProblemOrDiagnosisDetailsText ?? null,
      hasBloodBorneIllness: defaultValues?.hasBloodBorneIllness ?? null,
      bloodBorneIllnessDetails: defaultBloodBorneIllnessDetails.map(
        (condition) => {
          return {
            ...condition,
            checked:
              defaultValues?.bloodBorneIllnessDetails?.find(
                (item) => item.value === condition.value
              )?.checked ?? false,
          };
        }
      ),
      bloodBorneIllnessDetailsText:
        defaultValues?.bloodBorneIllnessDetailsText ?? null,
      hasPreviousSurgery: defaultValues?.hasPreviousSurgery ?? null,
      hasPreviousSurgeryDetails:
        defaultValues?.hasPreviousSurgeryDetails ?? null,
      hasPreviousAbdominalSurgery:
        defaultValues?.hasPreviousAbdominalSurgery ?? null,
      abdominalSurgeryDetails: defaultValues?.abdominalSurgeryDetails ?? null,
      hasMajorAccidentsOrInjuries:
        defaultValues?.hasMajorAccidentsOrInjuries ?? null,
      majorAccidentsOrInjuriesDetails:
        defaultValues?.majorAccidentsOrInjuriesDetails ?? null,
      isCurrentlyTakingMedicationsOrSuplements:
        defaultValues?.isCurrentlyTakingMedicationsOrSuplements ?? null,
      currentMedicationsOrSupplementsList:
        defaultValues?.currentMedicationsOrSupplementsList ?? [],
      hasAllergies: defaultValues?.hasAllergies ?? null,
      allergyList: defaultValues?.allergyList ?? null,
      hasFamilyMedicalConditions:
        defaultValues?.hasFamilyMedicalConditions ?? null,
      familyMedicalConditions: defaultFamilyMedicalConditions.map(
        (conditions) => {
          return {
            ...conditions,
            checked:
              defaultValues?.familyMedicalConditions?.find(
                (item) => item.value === conditions.value
              )?.checked ?? false,
          };
        }
      ),
      familyMedicalConditionsDetailsText:
        defaultValues?.familyMedicalConditionsDetailsText ?? null,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });
  return medicalHistoryInfoForm;
};
