import { CircularProgress } from '@mui/material';
import Typography from '../../../components/Typography';
import {
  AdditionalInfoFields,
  useAdditionalInfoForm,
} from '../hooks/useAdditionalInfoForm';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import dayjs from 'dayjs';
import { LAST_SAVED_ADDITIONAL_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle } from 'react';

const AdditionalInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit: () => additionalInfoForm.handleSubmit(),
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_ADDITIONAL_INFORMATION);
    const lastSavedString = lastSaved
      ? `Last saved 
    ${dayjs().diff(dayjs(lastSaved), 'minutes')}
     minutes ago`
      : '';

    const additionalInfoForm = useAdditionalInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_ADDITIONAL_INFORMATION,
            dayjs().toISOString()
          );
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<AdditionalInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      1000
    );

    const readonly = isPatientReadonly(patientInfo);
    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={additionalInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                additionalInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Additional Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <additionalInfoForm.Field
                    name="additionalInfo"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Anything else you would like us to know about your
                          pregnancy or medical history?
                        </Label>
                        <Input
                          name="additionalInfo"
                          placeholder="Additional information"
                          value={field.state.value}
                          onChange={(e) => field.handleChange(e.target.value)}
                          onBlur={() => {
                            if (field.state.meta.isDirty) {
                              additionalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default AdditionalInformation;
