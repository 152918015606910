export const LAST_SAVED_PERSONAL_INFORMATION =
  'LAST_SAVED_PERSONAL_INFORMATION';
export const LAST_SAVED_CONTACT_INFORMATION = 'LAST_SAVED_CONTACT_INFORMATION';
export const LAST_SAVED_RELATIONSHIP_INFORMATION =
  'LAST_SAVED_RELATIONSHIP_INFORMATION';
export const LAST_SAVED_INSURANCE_INFORMATION =
  'LAST_SAVED_INSURANCE_INFORMATION';
export const LAST_SAVED_CONTACT_LIST = 'LAST_SAVED_CONTACT_LIST';
export const LAST_SAVED_GYNECOLOGIC_HISTORY = 'LAST_SAVED_GYNECOLOGIC_HISTORY';
export const LAST_SAVED_PREGNANCY_INFORMATION =
  'LAST_SAVED_PREGNANCY_INFORMATION';
export const LAST_SAVED_OBSTETRIC_INFORMATION =
  'LAST_SAVED_OBSTETRIC_INFORMATION';
export const LAST_SAVED_MEDICAL_HISTORY_INFORMATION =
  'LAST_SAVED_MEDICAL_HISTORY_INFORMATION';
export const LAST_SAVED_LIFESTYLE_INFORMATION =
  'LAST_SAVED_LIFESTYLE_INFORMATION';
export const LAST_SAVED_SOCIAL_INFORMATION = 'LAST_SAVED_SOCIAL_INFORMATION';
export const LAST_SAVED_ADDITIONAL_INFORMATION =
  'LAST_SAVED_ADDITIONAL_INFORMATION';
export const LAST_SAVED_PREFERENCES_INFORMATION =
  'LAST_SAVED_PREFERENCES_INFORMATION';
