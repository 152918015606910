import { Tabs, tabsClasses } from '@mui/material';
import StyledTab from '../StyledTab';
import { useState } from 'react';
import Preferences from '../forms/Preferences';
import { LinkTab } from '../../../components/Tabs';
import { useParams } from 'react-router-dom';
import { usePatientInfoQuery } from '../queries/patient-info.query';
import { useUpdatePatientMutation } from '../mutations/update-patient.mutation';

const PreferencesTab = () => {
  const [value, setValue] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { patient } = useParams<{ patient: string }>();

  const { patientInfo, isPatientLoading } = usePatientInfoQuery(patient);

  const { updatePatientMutation } = useUpdatePatientMutation(
    patient as string,
    undefined,
    undefined,
    true
  );

  return (
    <div className="flex grow h-full relative">
      <Tabs
        orientation={'vertical'}
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className="shrink-0"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label="Communication Preferences" />
      </Tabs>
      <LinkTab value={value} index={0}>
        <Preferences
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
    </div>
  );
};

export default PreferencesTab;
