import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import {
  defaultPreviousInfectionsDiseasesOrSTI,
  defaultProblemsWithPeriods,
  defaultSexualActivityType,
  defaultSexualProtectionType,
} from '../defaults';

export type GynHistoryFields = Pick<
  PatientInfoDTO,
  | 'hasPeriods'
  | 'ageOfFirstPeriod'
  | 'arePeriodsRegular'
  | 'averagePeriodLength'
  | 'averagePeriodCycleLength'
  | 'problemsWithPeriods'
  | 'problemsWithPeriodsDetails'
  | 'sexuallyActive'
  | 'sexuallyActiveWith'
  | 'sexualActivityType'
  | 'ageOfFirstSexualActivity'
  | 'sexualPartnersNumber'
  | 'sexualProtectionType'
  | 'sexualProtectionTypeDetails'
  | 'painDuringSex'
  | 'hasHadPapTest'
  | 'lastPapTestDate'
  | 'lastPapTestResult'
  | 'papAbnormalityHistoryAndTreatment'
  | 'hasHadStiScreening'
  | 'lastSTIScreeningDate'
  | 'previousInfectionDiseasesOrSTI'
  | 'previousInfectionDiseasesOrSTIDetails'
  | 'hasHadMammogram'
  | 'lastMammogramDate'
  | 'mammogramAbnormalityHistoryAndTreatment'
> & {
  periodUnknown: boolean;
  cycleUnknown: boolean;
  unknownPap: boolean;
  unknownSTI: boolean;
  unknownMammogram: boolean;
};

export const useGynecologicHistoryInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<GynHistoryFields>) => void;
}) => {
  const gynecologicHistoryInfoForm = useForm({
    defaultValues: {
      hasPeriods: defaultValues?.hasPeriods ?? null,
      ageOfFirstPeriod: defaultValues?.ageOfFirstPeriod ?? null,
      arePeriodsRegular: defaultValues?.arePeriodsRegular ?? null,
      averagePeriodLength: defaultValues?.averagePeriodLength ?? null,
      periodUnknown: defaultValues?.averagePeriodLength ? false : true,
      averagePeriodCycleLength: defaultValues?.averagePeriodCycleLength ?? null,
      cycleUnknown: defaultValues?.averagePeriodCycleLength ? false : true,
      problemsWithPeriods: defaultProblemsWithPeriods.map((condition) => {
        return {
          ...condition,
          checked:
            defaultValues?.problemsWithPeriods?.find(
              (item) => item.value === condition.value
            )?.checked ?? false,
        };
      }),
      problemsWithPeriodsDetails:
        defaultValues?.problemsWithPeriodsDetails ?? null,
      sexuallyActive: defaultValues?.sexuallyActive ?? undefined,
      sexuallyActiveWith: defaultValues?.sexuallyActiveWith ?? null,
      sexualActivityType: defaultSexualActivityType.map((condition) => {
        return {
          ...condition,
          checked:
            defaultValues?.sexualActivityType?.find(
              (item) => item.value === condition.value
            )?.checked ?? false,
        };
      }),
      ageOfFirstSexualActivity: defaultValues?.ageOfFirstSexualActivity ?? null,
      sexualPartnersNumber: defaultValues?.sexualPartnersNumber ?? null,
      sexualProtectionType: defaultSexualProtectionType.map((condition) => {
        return {
          ...condition,
          checked:
            defaultValues?.sexualProtectionType?.find(
              (item) => item.value === condition.value
            )?.checked ?? false,
        };
      }),
      sexualProtectionTypeDetails:
        defaultValues?.sexualProtectionTypeDetails ?? null,
      painDuringSex: defaultValues?.painDuringSex ?? null,
      unknownPap: defaultValues?.lastPapTestDate ? false : true,
      hasHadPapTest: defaultValues?.hasHadPapTest ?? null,
      lastPapTestDate: defaultValues?.lastPapTestDate ?? null,
      lastPapTestResult: defaultValues?.lastPapTestResult ?? null,
      papAbnormalityHistoryAndTreatment:
        defaultValues?.papAbnormalityHistoryAndTreatment ?? null,
      hasHadStiScreening: defaultValues?.hasHadStiScreening ?? null,
      lastSTIScreeningDate: defaultValues?.lastSTIScreeningDate ?? null,
      previousInfectionDiseasesOrSTI:
        defaultPreviousInfectionsDiseasesOrSTI.map((condition) => {
          return {
            ...condition,
            checked:
              defaultValues?.previousInfectionDiseasesOrSTI?.find(
                (item) => item.value === condition.value
              )?.checked ?? false,
          };
        }),
      previousInfectionDiseasesOrSTIDetails:
        defaultValues?.previousInfectionDiseasesOrSTIDetails ?? null,
      hasHadMammogram: defaultValues?.hasHadMammogram ?? null,
      lastMammogramDate: defaultValues?.lastMammogramDate ?? null,
      mammogramAbnormalityHistoryAndTreatment:
        defaultValues?.mammogramAbnormalityHistoryAndTreatment ?? null,
    } as unknown as GynHistoryFields,
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });
  return gynecologicHistoryInfoForm;
};
