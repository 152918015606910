import { useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';

const fetchGuestPatient = async (token: string) => {
  const response = await axios.get<PatientInfoDTO>(
    `/v2/i/patients/by-token/${token}`
  );
  return response.data;
};

export const useGuestPatientInfoByToken = (token: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['guest-patient-info', token],
    queryFn: () => fetchGuestPatient(token),
  });
  return {
    patientInfo: data,
    isPatientLoading: isLoading,
    refetchPatient: refetch,
  };
};
