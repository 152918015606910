import * as React from 'react';
import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cn } from '@aster/client/utils';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioPrimitive.Root
    ref={ref}
    aria-label="radio-group"
    className={cn('flex items-center gap-2 relative', className)}
    {...props}
  />
));

const RadioIndicator = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof RadioPrimitive.Indicator>) => (
  <RadioPrimitive.Indicator
    className={cn(
      `relative flex size-full items-center justify-center after:block after:size-[14px] after:rounded-full after:bg-aster-main`,
      className
    )}
    {...props}
  />
);

const RadioItem = React.forwardRef<
  React.ElementRef<typeof RadioPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioPrimitive.Item>
>(({ className, ...props }, ref) => (
  <RadioPrimitive.Item
    ref={ref}
    className={cn(
      'size-[18px] cursor-pointer rounded-full bg-white outline outline-1 outline-black shadow-aster-main [&:not([data-state=checked])]:hover:bg-gray-300 data-[state=checked]:outline-aster-main',
      className
    )}
    {...props}
  />
));

export { RadioGroup, RadioItem, RadioIndicator };
