import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { ContactInfo, PatientInfoDTO } from '@aster/shared/dtos/patient';
import dayjs from 'dayjs';

export const useContactListInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO;
  onSubmit: (value: {
    contactList: Array<Partial<ContactInfo>>;
    preferredPharmacyName: string;
    preferredPharmacyPhone: string;
    preferredPharmacyAddress: string;
    howDidFindPractice: PatientInfoDTO['howDidFindPractice'];
    practiceFindingDetails: string;
  }) => void;
}) => {
  const contactListInfoForm = useForm({
    defaultValues: {
      emergencyName: defaultValues?.contactList?.[0]?.name ?? '',
      emergencyRelationship:
        defaultValues?.contactList?.[0]?.relationship ?? '',
      emergencyPhone: defaultValues?.contactList?.[0]?.phoneNumber ?? '',
      emergencyEmail: defaultValues?.contactList?.[0]?.email ?? '',
      emergencyAddress: defaultValues?.contactList?.[0]?.notes ?? '',
      physicianName: defaultValues?.contactList?.[1]?.name ?? '',
      physicianPhone: defaultValues?.contactList?.[1]?.phoneNumber ?? '',
      physicianEmail: defaultValues?.contactList?.[1]?.email ?? '',
      physicianAddress: defaultValues?.contactList?.[1]?.notes ?? '',
      physicianFax: defaultValues?.contactList?.[1]?.fax ?? '',
      providerName: defaultValues?.contactList?.[2]?.name ?? '',
      providerPhone: defaultValues?.contactList?.[2]?.phoneNumber ?? '',
      providerEmail: defaultValues?.contactList?.[2]?.email ?? '',
      providerAddress: defaultValues?.contactList?.[2]?.notes ?? '',
      providerRelationship: defaultValues?.contactList?.[2]?.relationship ?? '',
      otherName: defaultValues?.contactList?.[3]?.name ?? '',
      otherPhone: defaultValues?.contactList?.[3]?.phoneNumber ?? '',
      otherEmail: defaultValues?.contactList?.[3]?.email ?? '',
      otherAddress: defaultValues?.contactList?.[3]?.notes ?? '',
      otherType: defaultValues?.contactList?.[3]?.type ?? '',
      preferredPharmacyName: defaultValues?.preferredPharmacyName ?? '',
      preferredPharmacyPhone: defaultValues?.preferredPharmacyPhone ?? '',
      preferredPharmacyAddress: defaultValues?.preferredPharmacyAddress ?? '',
      howDidFindPractice: defaultValues?.howDidFindPractice,
      practiceFindingDetails: defaultValues?.practiceFindingDetails ?? '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const contactList = [
        {
          name: value.emergencyName ?? '',
          relationship: value.emergencyRelationship ?? '',
          phoneNumber: value.emergencyPhone ?? '',
          email: value.emergencyEmail ?? '',
          notes: value.emergencyAddress ?? '',
          type: 'emergency',
        },
        {
          name: value.physicianName ?? '',
          phoneNumber: value.physicianPhone ?? '',
          email: value.physicianEmail ?? '',
          fax: value.physicianFax ?? '',
          notes: value.physicianAddress ?? '',
          relationship: 'pcp',
          type: 'pcp',
        },
        {
          name: value.providerName ?? '',
          phoneNumber: value.providerPhone ?? '',
          email: value.providerEmail ?? '',
          notes: value.providerAddress ?? '',
          relationship: value.providerRelationship ?? '',
          type: 'provider',
        },
        {
          name: value.otherName ?? '',
          phoneNumber: value.otherPhone ?? '',
          email: value.otherEmail ?? '',
          notes: value.otherAddress ?? '',
          relationship: value.otherType ?? '',
          type: 'other',
        },
      ];

      onSubmit({
        contactList,
        preferredPharmacyName: value.preferredPharmacyName ?? '',
        preferredPharmacyPhone: value.preferredPharmacyPhone ?? '',
        preferredPharmacyAddress: value.preferredPharmacyAddress ?? '',
        howDidFindPractice: value.howDidFindPractice ?? null,
        practiceFindingDetails: value.practiceFindingDetails ?? '',
      });
    },
  });
  return contactListInfoForm;
};
