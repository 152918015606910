import Typography from '../../../components/Typography';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { GoogleAutoComplete } from './GoogleAutoComplete';
import { CircularProgress } from '@mui/material';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { useContactListInfoForm } from '../hooks/useContactListInfoForm';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { getAddressFieldsFromGoogle } from '../utils/get-address-fields-from-google';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { LAST_SAVED_CONTACT_LIST } from '../constants';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';

const ContactList = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit: () => contactListInfoForm.handleSubmit(),
    }));

    const emergencyAddressRef = useRef<HTMLInputElement>(null);
    const pcpAddressRef = useRef<HTMLInputElement>(null);
    const providerAddressRef = useRef<HTMLInputElement>(null);
    const pharmacyAddressRef = useRef<HTMLInputElement>(null);

    const { patient } = useParams<{ patient: string }>();

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_CONTACT_LIST);
    const lastSavedString = lastSaved
      ? `Last saved 
    ${dayjs().diff(dayjs(lastSaved), 'minutes')}
     minutes ago`
      : '';

    const contactListInfoForm = useContactListInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save({
          contactList: value.contactList,
          preferredPharmacyName: value.preferredPharmacyName,
          preferredPharmacyPhone: value.preferredPharmacyPhone,
          preferredPharmacyAddress: value.preferredPharmacyAddress,
          howDidFindPractice:
            value.howDidFindPractice as PatientInfoDTO['howDidFindPractice'],
          practiceFindingDetails: value.practiceFindingDetails,
        })?.then(() => {
          storePreference(LAST_SAVED_CONTACT_LIST, dayjs().toISOString());
          readPreference<string>(LAST_SAVED_CONTACT_LIST);
        });
      },
    });

    const save = useDebouncedCallback(async (value) => {
      await updatePatientMutation.mutateAsync(value);
    }, 1000);

    const readonly = isPatientReadonly(patientInfo);

    const updateContactAddress = (
      place: google.maps.places.PlaceResult,
      fieldName:
        | 'emergencyAddress'
        | 'physicianAddress'
        | 'providerAddress'
        | 'preferredPharmacyAddress'
    ) => {
      const addressFields = getAddressFieldsFromGoogle(place);
      if (!addressFields) return;
      const { streetAddress1, city, state, zipCode } = addressFields;
      const fullAddress = `${streetAddress1}, ${city}, ${state}, ${zipCode}`;
      contactListInfoForm.setFieldValue(fieldName, fullAddress);
    };

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={contactListInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                contactListInfoForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap h-fit overflow-hidden gap-10 xl:gap-40">
                <div className="flex flex-col gap-y-5 w-full">
                  <Typography
                    text="Contact List"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>

                  <div className="flex flex-wrap gap-5">
                    <div className="flex flex-col bg-gray-100 rounded-[10px] p-5 w-[400px]">
                      <div className="flex items-center gap-x-1">
                        <NotificationImportantRoundedIcon
                          color="error"
                          fontSize="medium"
                        />
                        <Typography variant="h6" text="Emergency Contact" />
                      </div>

                      <div className="flex flex-col gap-y-5 mt-4">
                        <div className="flex gap-5 w-full">
                          <contactListInfoForm.Field
                            name="emergencyName"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Name</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="Name"
                                  type={'text'}
                                  value={field.state.value ?? ''}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                          <contactListInfoForm.Field
                            name="emergencyRelationship"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Relationship</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="Relationship"
                                  type={'text'}
                                  value={field.state.value ?? ''}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                        </div>
                        <div className="flex gap-5 w-full">
                          <contactListInfoForm.Field
                            name="emergencyEmail"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Email</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="Email"
                                  type={'email'}
                                  value={field.state.value ?? ''}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                          <contactListInfoForm.Field
                            name="emergencyPhone"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Phone</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="(xxx) xxx-xxxx"
                                  type={'tel'}
                                  value={field.state.value}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                        </div>
                        <contactListInfoForm.Field
                          name="emergencyAddress"
                          children={(field) => (
                            <FormControl>
                              <Label>Address</Label>
                              <GoogleAutoComplete
                                inputProps={{
                                  className: 'bg-white',
                                  name: 'emergencyAddress',
                                  value: field.state.value,
                                  onChange: (evt) =>
                                    field.handleChange(evt.target.value),
                                  onBlur: (evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  },
                                  disabled: readonly,
                                }}
                                selectedAutocomplete={(place) =>
                                  updateContactAddress(
                                    place,
                                    'emergencyAddress'
                                  )
                                }
                                ref={emergencyAddressRef}
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col bg-gray-100 rounded-[10px] p-5 w-[400px]">
                      <div className="flex items-center gap-x-1">
                        <LocalHospitalRoundedIcon
                          color="primary"
                          fontSize="medium"
                        />
                        <Typography
                          variant="h6"
                          text="Primary Care Physician (PCP)"
                        />
                      </div>

                      <div className="flex flex-col gap-y-5 mt-4">
                        <div className="flex gap-5 w-full">
                          <contactListInfoForm.Field
                            name="physicianName"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Name</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="Name"
                                  type={'text'}
                                  value={field.state.value}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                          <contactListInfoForm.Field
                            name="physicianPhone"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Label>Phone</Label>
                                <Input
                                  className="bg-white"
                                  placeholder="(xxx) xxx-xxxx"
                                  type={'tel'}
                                  value={field.state.value}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                        </div>

                        <contactListInfoForm.Field
                          name="physicianEmail"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Email</Label>
                              <Input
                                className="bg-white"
                                placeholder="Email"
                                type={'email'}
                                value={field.state.value}
                                onChange={(evt) =>
                                  field.handleChange(evt.target.value)
                                }
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="physicianFax"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Fax</Label>
                              <Input
                                className="bg-white"
                                placeholder="(xxx) xxx-xxxx"
                                type={'tel'}
                                value={field.state.value}
                                onChange={(evt) =>
                                  field.handleChange(evt.target.value)
                                }
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="physicianAddress"
                          children={(field) => (
                            <FormControl>
                              <Label>Address</Label>
                              <GoogleAutoComplete
                                inputProps={{
                                  className: 'bg-white',
                                  name: 'physicianAddress',
                                  value: field.state.value,
                                  onChange: (evt) =>
                                    field.handleChange(evt.target.value),
                                  onBlur: (evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  },
                                  disabled: readonly,
                                }}
                                selectedAutocomplete={(place) =>
                                  updateContactAddress(
                                    place,
                                    'physicianAddress'
                                  )
                                }
                                ref={pcpAddressRef}
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col bg-gray-100 rounded-[10px] p-5 w-[400px]">
                      <div className="flex items-center gap-x-1">
                        <ContactEmergencyRoundedIcon
                          color="info"
                          fontSize="medium"
                        />
                        <Typography variant="h6" text="Current Providers" />
                      </div>

                      <div className="flex flex-col gap-y-5 mt-4">
                        <contactListInfoForm.Field
                          name="providerRelationship"
                          children={(field) => (
                            <FormControl className="relative">
                              <Label>
                                Are you currently receiving care from a midwife,
                                or OB/GYN?
                              </Label>
                              <Select
                                value={field.state.value}
                                defaultValue={field.state.value}
                                onValueChange={(value) => {
                                  field.handleChange(value);
                                  contactListInfoForm.handleSubmit();
                                }}
                                disabled={readonly}
                              >
                                <SelectTrigger className="bg-white">
                                  <SelectValue placeholder={'Select Type'} />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="obgyn">
                                      OB/GYN
                                    </SelectItem>
                                    <SelectItem value="midwife">
                                      Midwife
                                    </SelectItem>
                                    <SelectItem value="doula">Doula</SelectItem>
                                    <SelectItem value="nutritionist">
                                      Nutritionist
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="providerName"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Name</Label>
                              <Input
                                className="bg-white"
                                placeholder="Name"
                                type={'text'}
                                value={field.state.value}
                                onChange={(evt) => {
                                  field.handleChange(evt.target.value);
                                }}
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="providerPhone"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Phone</Label>
                              <Input
                                className="bg-white"
                                placeholder="(xxx) xxx-xxxx"
                                type={'tel'}
                                value={field.state.value}
                                onChange={(evt) => {
                                  field.handleChange(evt.target.value);
                                }}
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="providerAddress"
                          children={(field) => (
                            <FormControl>
                              <Label>Address</Label>
                              <GoogleAutoComplete
                                inputProps={{
                                  className: 'bg-white',
                                  name: 'providerAddress',
                                  value: field.state.value,
                                  onChange: (evt) =>
                                    field.handleChange(evt.target.value),
                                  onBlur: (evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  },
                                  disabled: readonly,
                                }}
                                selectedAutocomplete={(place) =>
                                  updateContactAddress(place, 'providerAddress')
                                }
                                ref={providerAddressRef}
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col bg-gray-100 rounded-[10px] p-5 w-[400px]">
                      <div className="flex items-center gap-x-1">
                        <LocalPharmacyIcon color="primary" fontSize="medium" />
                        <Typography variant="h6" text="Preferred Pharmacy" />
                      </div>
                      <div className="flex flex-col gap-y-5 mt-4">
                        <contactListInfoForm.Field
                          name="preferredPharmacyName"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Pharmacy Name</Label>
                              <Input
                                className="bg-white"
                                placeholder="Pharmacy Name"
                                type={'text'}
                                value={field.state.value ?? ''}
                                onChange={(evt) =>
                                  field.handleChange(evt.target.value)
                                }
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="preferredPharmacyPhone"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Phone</Label>
                              <Input
                                className="bg-white"
                                placeholder="(xxx) xxx-xxxx"
                                type={'tel'}
                                value={field.state.value ?? ''}
                                onChange={(evt) =>
                                  field.handleChange(evt.target.value)
                                }
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="preferredPharmacyAddress"
                          children={(field) => (
                            <FormControl>
                              <Label>Address</Label>
                              <GoogleAutoComplete
                                inputProps={{
                                  className: 'bg-white',
                                  name: 'preferredPharmacyAddress',
                                  value: field.state.value ?? '',
                                  onChange: (evt) =>
                                    field.handleChange(evt.target.value),
                                  onBlur: (evt) => {
                                    if (field.state.meta.isDirty) {
                                      contactListInfoForm.handleSubmit();
                                    }
                                  },
                                  disabled: readonly,
                                }}
                                selectedAutocomplete={(place) =>
                                  updateContactAddress(
                                    place,
                                    'preferredPharmacyAddress'
                                  )
                                }
                                ref={pharmacyAddressRef}
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col bg-gray-100 rounded-[10px] p-5 w-[400px]">
                      <div className="flex items-center gap-x-1">
                        <Diversity1RoundedIcon
                          color="success"
                          fontSize="medium"
                        />
                        <Typography variant="h6" text="Referral" />
                      </div>

                      <div className="flex flex-col gap-y-5 mt-4">
                        <contactListInfoForm.Field
                          name="howDidFindPractice"
                          children={(field) => (
                            <FormControl className="relative">
                              <Label>
                                How did you hear about our practice?
                              </Label>
                              <Select
                                value={field.state.value ?? ''}
                                defaultValue={field.state.value ?? ''}
                                onValueChange={(value) => {
                                  field.handleChange(
                                    value as PatientInfoDTO['howDidFindPractice']
                                  );
                                  contactListInfoForm.handleSubmit();
                                }}
                                disabled={readonly}
                              >
                                <SelectTrigger className="bg-white">
                                  <SelectValue placeholder={'Select Type'} />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="friend">
                                      Friend
                                    </SelectItem>
                                    <SelectItem value="family">
                                      Family Member
                                    </SelectItem>
                                    <SelectItem value="internet_search">
                                      Internet Search
                                    </SelectItem>
                                    <SelectItem value="community">
                                      Community
                                    </SelectItem>
                                    <SelectItem value="social_media">
                                      Social Media
                                    </SelectItem>
                                    <SelectItem value="healthcare_provider">
                                      Healthcare Provider
                                    </SelectItem>
                                    <SelectItem value="other">Other</SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                          )}
                        />
                        <contactListInfoForm.Field
                          name="practiceFindingDetails"
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Details</Label>
                              <Input
                                className="bg-white"
                                placeholder="Details"
                                type={'text'}
                                value={field.state.value ?? ''}
                                onChange={(evt) =>
                                  field.handleChange(evt.target.value)
                                }
                                onBlur={(evt) => {
                                  if (field.state.meta.isDirty) {
                                    contactListInfoForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                            </FormControl>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default ContactList;
