import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { CreatePatientDTO, PatientInfoDTO } from '@aster/shared/dtos/patient';
import { useSnackbar } from '../../../components/Snack';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../../src/mutations/utils/create-mutation-scope-id';

export function useUpdatePatientMutation(
  patientID: string,
  onSuccessCb?: () => void,
  onErrorCb?: () => void,
  hideSuccessMessage?: boolean
) {
  const queryClient = useQueryClient();
  const createProfileMutation = async (
    newForm: CreatePatientDTO | Partial<PatientInfoDTO>
  ) => axios.put(`/v2/patients/${patientID}`, newForm);
  const { showMessage } = useSnackbar();

  const updatePatientMutation = useMutation({
    mutationKey: ['createProfile'],
    mutationFn: createProfileMutation,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT, patientID),
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patient', patientID] });
      queryClient.invalidateQueries({
        queryKey: ['patientForLabs', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['isPatientValidForLabs', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      if (!hideSuccessMessage) {
        showMessage({
          type: 'success',
          message: 'The patient information has been saved',
        });
      }
      onSuccessCb && onSuccessCb();
    },
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while saving the patient information.',
      });
      onErrorCb && onErrorCb();
    },
  });

  return { updatePatientMutation };
}
